import LogoCaixa from '../../../assets/img/caixaLogo.png'

function Mcmv() {
    return (
        <div className='parceria'>
            <h2>Em parceria com</h2>
            <div>
                <img src={LogoCaixa} alt="CAIXA" />
            </div>

        </div>
    )
}
export default Mcmv